import Swiper from 'swiper';
import { Navigation } from 'swiper/modules';
import gsap from "gsap";

import { getElementByDataType, safeCall } from '../../common/utils';

import 'swiper/css';

safeCall(() => {
  const $section = getElementByDataType('our-x-factors-section');


  if (!$section) return;

  const $slider = getElementByDataType('our-x-factors-slider', $section);
  const $sliderPagination = getElementByDataType(
    'our-x-factors-slider-pagination',
    $section
  );
  const $navPrev = getElementByDataType('slider-nav-prev', $section);
  const $navNext = getElementByDataType('slider-nav-next', $section);



  new Swiper($slider, {
    modules: [Navigation],
    navigation: {
      enabled: true,
      nextEl: $navNext,
      prevEl: $navPrev
    },
    on: {
      slideChange: (swiper) => {
        const { activeIndex, slides } = swiper;

        const $activeSlide = slides[activeIndex];

        const slideTitle = getElementByDataType(
          'our-x-factors-slide-title',
          $activeSlide
        ).textContent;

        $sliderPagination.textContent = slideTitle;
      }
    }
  });
});
