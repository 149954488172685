import Swiper from 'swiper';
import { Navigation, Pagination, EffectFade } from 'swiper/modules';
import gsap from 'gsap';

import {
  customFormatFraction,
  getElementByDataType,
  safeCall,
  visibleSwiperNav
} from '../../common/utils';

import 'swiper/css';
import 'swiper/css/effect-fade';

const forwardGradient = 'linear-gradient(to right, #fff 0%, #fff var(--fill-progress), transparent var(--fill-progress), transparent 100% )'
const backwardGradient = 'linear-gradient(to left, #fff 0%, #fff var(--fill-progress), transparent var(--fill-progress), transparent 100% )'

safeCall(() => {
  const $slider = getElementByDataType('how-it-works-slider');
  const $pagination = getElementByDataType('slider-pagination', $slider);
  const $navPrev = getElementByDataType('slider-nav-prev', $slider);
  const $navNext = getElementByDataType('slider-nav-next', $slider);

  const images = Array.from($slider.querySelectorAll('[data-type="slide-image"]'));
  const titles = Array.from($slider.querySelectorAll('[data-type="slide-title"]'));
  const texts = Array.from($slider.querySelectorAll('[data-type="slide-text"]'));

  new Swiper($slider, {
    modules: [Navigation, Pagination, EffectFade],
    loop: false,
    slidesPerView: 1,
    speed: 0,
    navigation: {
      enabled: true,
      nextEl: $navNext,
      prevEl: $navPrev
    },
    effect: 'fade',
    pagination: {
      enabled: true,
      el: $pagination,
      clickable: true,
      renderBullet: (index, className) => {
        const pagin = pagins[index];
        return `<span class="${className}">${pagin}</span>`;
      }
    },
    on: {
      init: (swiper) => {
        visibleSwiperNav(swiper.slides);
        const $images = Array.from($slider.querySelectorAll('[data-type="slide-image"]'));
        gsap.set($images, { '--fill-progress': '0%', mask : forwardGradient });
        gsap.to($images[0], { '--fill-progress': '100%', duration: 0.6, ease: 'power2.out' });
      },
      slideChange: (swiper) => {
        const { slides, previousIndex, activeIndex } = swiper;

        const $prevSlide = slides[previousIndex];
        const $nextSlide = slides[activeIndex];

        const $prevTitle = titles[previousIndex];
        const $prevText = texts[previousIndex];
        const $prevImage = images[previousIndex];

        const $nextTitle = titles[activeIndex];
        const $nextText = texts[activeIndex];
        const $nextImage = images[activeIndex];

        gsap.timeline({
          onInterrupt: () => {
            gsap.set(images, {
              '--fill-progress': '0%',
              'mask' : backwardGradient
            })
            gsap.set($nextImage, {
              '--fill-progress': '100%',
              'mask' : forwardGradient
            })
            gsap.set(texts, { y: 0, opacity: 1 })
            gsap.set(titles, { y: 0, opacity: 1 })
            gsap.set($nextSlide, { opacity: 1 })
            gsap.set($prevSlide, { opacity: 0 })
          },
          defaults: {
            ease: 'power1.out',
            duration: 0.5,
            stagger: 0.02
          }
        }).set($prevSlide, { opacity: 1 })
          .set($nextSlide, { zIndex: 10 })
          .set($prevImage, { 'mask' : backwardGradient })
          .set($nextImage, { 'mask' : forwardGradient })
          .fromTo(
            [$prevText, $prevTitle],
            { y: 0, opacity: 1 },
            { y: 20, opacity: 0 }
          )
          .to($prevImage, { '--fill-progress': '0%' }, '-=0.3')
          .to($nextImage, { '--fill-progress': '100%' }, '-=0.4')
          .set($prevImage, { mask : forwardGradient, '--fill-progress': '0%' })
          .set($prevSlide, { opacity: 0 })
          .set($nextSlide, { zIndex: 0 })
          .fromTo(
            [$nextTitle, $nextText],
            { y: -20, opacity: 0 },
            { y: 1, opacity: 1 },
            '-=0.2'
          );
      }
    }
  });
});
