import {safeCall} from "../../common/utils";
import Choices  from "choices.js";

safeCall(() => {
  const selects = document.querySelectorAll('select.choices')
  if(!selects.length) return

  const classNames = {
    hideDropdown: 'hide-dropdown',
    hideItems: 'hide-items'
  }

  selects.forEach(item => {
    const selectedValuePrefix = item.dataset.prefix
    new Choices(item, {
      searchEnabled: !!item.dataset.search,
      shouldSort: false,
      noResultsText: item.dataset.noResultsText || 'No results found',
      noChoicesText: item.dataset.noResultsText || 'No choices to choose from',
      itemSelectText: '',
      defaultItem: item.dataset.defaultItem,
      callbackOnCreateTemplates: function(template) {
        return {
          item: ({classNames}, { id, value, label, active, disabled, highlighted }) => {
            return template(`
              <div class="${classNames.item} ${highlighted ? classNames.highlightedState : classNames.itemSelectable}"
                data-item
                data-id="${id}"
                data-value="${value}"
                ${active ? 'aria-selected="true"' : ''}
                ${disabled ? 'aria-disabled="true"' : ''}>
                ${selectedValuePrefix || ''} ${label}
              </div>
            `);
          }
        };
      },
    })
  })
})
