document.addEventListener('DOMContentLoaded', function() {
    function initializeVideoPlayer(videoId, playPauseId, muteId, progressBarId) {
        const video = document.getElementById(videoId);
        const progressBar = document.getElementById(progressBarId);
        if(!video) return

        function updateProgressBar() {
            const value = (video.currentTime / video.duration) * 100;
            progressBar.style.width = `${value}%`;

            if (!video.paused && !video.ended) {
                requestAnimationFrame(updateProgressBar);
            }
        }


        document.getElementById(playPauseId).addEventListener('click', function() {
            if (video.paused || video.ended) {
                video.play();
                requestAnimationFrame(updateProgressBar);
            } else {
                video.pause();
            }
        });


        document.getElementById(muteId).addEventListener('click', function() {
            video.muted = !video.muted;
        });


        video.addEventListener('play', function() {
            requestAnimationFrame(updateProgressBar);
        });

        progressBar.parentNode.addEventListener('click', function(e) {
            const rect = this.getBoundingClientRect();
            const offsetX = e.clientX - rect.left;
            const seekTime = (offsetX / this.offsetWidth) * video.duration;
            video.currentTime = seekTime;
            updateProgressBar();
        });
    }


    initializeVideoPlayer('desktopVideo', 'desktopPlayPause', 'desktopMute', 'desktopProgressBar');
    initializeVideoPlayer('mobileVideo', 'mobilePlayPause', 'mobileMute', 'mobileProgressBar');
});



