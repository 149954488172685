document.addEventListener('DOMContentLoaded', function() {
    const nav = document.querySelector('.navigation');
    const navBlock = document.querySelector('.navigation-block');
    const title = document.querySelector('.navigation-title');
    const iconContainer = document.getElementById('nav-icon');
    const config = {
        fixedNav: true,
        fixedClasses: ['max-lg:!fixed', 'max-lg:!left-0', 'max-lg:!z-10']
    };

    const onScroll = () => {
        if (navBlock) {  // Check if navBlock exists
            const navBlockTop = navBlock.getBoundingClientRect().top;
            const scrollPosition = window.scrollY;

            if (navBlockTop <= 0 && !config.fixedNav) {
                nav.classList.add(...config.fixedClasses);
                config.fixedNav = true;
            } else if (config.fixedNav && navBlockTop > 0) {
                nav.classList.remove(...config.fixedClasses);
                config.fixedNav = false;
            }
        }
    };

    onScroll();
    document.addEventListener('scroll', onScroll);

    const links = document.querySelectorAll('.navigation a');
    const stickyNav = document.querySelector('.navigation'); 
    const extraOffset = 3.75 * 16; // Convert 3.75rem to pixels (1rem = 16px)
    const originalIcon = `
        <svg class="nav-icons w-2 h-auto pt-1 lg:hidden" xmlns="http://www.w3.org/2000/svg" width="12" height="8" viewBox="0 0 12 8" fill="none">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M12 2.125C9.1005 2.125 6.75 4.47551 6.75 7.375L5.25 7.375C5.25 4.47551 2.8995 2.125 -6.55671e-08 2.125L9.53674e-07 0.624999C2.6122 0.625 4.87783 2.10883 6 4.2796C7.12217 2.10883 9.3878 0.625 12 0.625L12 2.125Z" fill="black"></path>
        </svg>`;

    const rotatedIcon = `
        <svg class="nav-icons w-2 h-auto pt-1 lg:hidden" xmlns="http://www.w3.org/2000/svg" width="12" height="8" viewBox="0 0 12 8" fill="none" style="transform: rotate(180deg);">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M12 2.125C9.1005 2.125 6.75 4.47551 6.75 7.375L5.25 7.375C5.25 4.47551 2.8995 2.125 -6.55671e-08 2.125L9.53674e-07 0.624999C2.6122 0.625 4.87783 2.10883 6 4.2796C7.12217 2.10883 9.3878 0.625 12 0.625L12 2.125Z" fill="black"></path>
        </svg>`;

    links.forEach(link => {
        link.addEventListener('click', function(e) {
            e.preventDefault(); 

            const targetId = this.getAttribute('href').substring(1); 
            const targetElement = document.getElementById(targetId);

            if (targetElement) {
                const stickyNavHeight = stickyNav.offsetHeight; 
                const totalOffset = stickyNavHeight + extraOffset; 
                const targetPosition = targetElement.getBoundingClientRect().top + window.scrollY - totalOffset;

                window.scrollTo({
                    top: targetPosition,
                    behavior: 'smooth'
                });
            }
        });
    });

    if (nav) {
        title.addEventListener('click', function() {
            if (window.innerWidth >= 1024) return;
            if (nav.dataset.isOpen === 'true') {
                nav.dataset.isOpen = 'false';
                nav.style.maxHeight = '';
                iconContainer.innerHTML = originalIcon;
            } else {
                nav.dataset.isOpen = 'true';
                nav.style.maxHeight = '100%';
                iconContainer.innerHTML = rotatedIcon;
            }
        });
    }
});