import {safeCall} from "../../common/utils";
import gsap from "gsap";
import SplitType from 'split-type'

safeCall(()=> {
  const headerMain = document.querySelector('header');
  if(headerMain) {
    const withSubMenus = document.querySelectorAll('.navigation-dropdown')
    if(!withSubMenus.length) return

    withSubMenus.forEach(item => {
      item.querySelector('.navigation-dropdown-label').addEventListener('click', () => {
        item.classList.add('is-open')
      })
      item.querySelector('.navigation-back').addEventListener('click', (e) => {
        e.preventDefault()
        e.stopPropagation()
        item.classList.remove('is-open')
      })
    })

    const menuButton = document.querySelector('.menu-button')
    const headerOpenText = menuButton.dataset.openText
    const headerCloseText = menuButton.dataset.closeText
    const closeOnClickButtons = document.querySelectorAll('.js-close-on-click')


    closeOnClickButtons.forEach(item => {
      item.addEventListener('click', () => {
        headerMain.classList.remove('is-open')
        headerMain.scrollTop = 0
        menuButton.innerText = headerCloseText
        withSubMenus.forEach(item => {
          item.classList.remove('is-open')
        })
      })
    })

    menuButton.addEventListener('click', () => {
      const isOpen = headerMain.classList.contains('is-open')
      headerMain.classList.toggle('is-open')
      headerMain.scrollTop = 0
      menuButton.innerText = headerMain.classList.contains('is-open') ?  headerOpenText : headerCloseText
      if(isOpen) {
        withSubMenus.forEach(item => {
          item.classList.remove('is-open')
        })
      }
    })

    const onResize = () => {
      if(window.innerWidth > 1024) {
        headerMain.classList.remove('is-open')
        menuButton.innerText = headerCloseText
        withSubMenus.forEach(item => {
          item.classList.remove('is-open')
        })
      }
    }
    onResize()
    window.addEventListener('resize', onResize)
  }
})

safeCall(() => {
  const preloader = document.querySelector('.preloader')
  if(!preloader) return

  const config = {
    percentage: 0
  }

  const line = document.querySelector('.loader-line')
  const percentages = document.querySelectorAll('.loader-inner')
  const percentageFill = document.querySelector('.loader-inner-fill')

  const percentageBorder = document.querySelector('.loader-border')
  // const loaderText = document.querySelector('.loader-text')
  const loaderNumbers = document.querySelectorAll('.loader-numbers')


  const splitText = new SplitType('.loader-text', {types: 'words, chars'})
  const chars = splitText.chars
  gsap.set(percentageBorder, {width: 0})
  gsap.set(loaderNumbers, {x: -30, opacity: 0})
  gsap.set(chars, {opacity: 0})



  window.addEventListener('beforeunload', () => {
    if (history.scrollRestoration) {
      history.scrollRestoration = "manual";
    }
  })

  gsap.set('.anim-start-header-part', {opacity:0, y:-20})
  gsap.set('.anim-start-line-hor', {'--insetValue': '100%', clipPath: 'inset(0 var(--insetValue) 0 0)'})
  gsap.set('.anim-start-line-vert', {'--insetValue': '100%', clipPath: 'inset(0 0 var(--insetValue) 0)'})
  gsap.set('.anim-start-site-item', {x: -30, opacity: 0})
  gsap.set('.anim-start-show-ltr', {'--insetValue': '100%', clipPath: 'inset(0 var(--insetValue) 0 0)'})
  gsap.set('.anim-start-masked-text', {'--text-fill': '0%'})

  const tl = gsap.timeline({
    defaults: {
      ease: 'power1.out',
    },
    onComplete: () => {
      preloader.remove()
    }
  })
  tl.to(chars, {
    opacity: 1,
    stagger: 0.04,
    duration: 0.6
  }, 0)
  tl.to(percentageBorder, {
    width: '100%',
    duration: 1
  }, 0)
  tl.to(loaderNumbers, {
    x: 0,
    opacity: 1,
    duration: 0.5
  }, 0.5)
  tl.to(config, {
    percentage: 100,
    duration: 3,
    ease: 'power4.inOut',
    onUpdate: () => {
      percentageFill.style.clipPath = 'inset(0 ' + (100 - config.percentage.toFixed(0)) + '% 0 0)'
      line.style.width = config.percentage + '%'
      percentages.forEach(el => {
        el.innerText = config.percentage.toFixed(0)
      })
    }
  })
  tl.to(preloader, {
    top: '-110%',
    delay: 0.5,
    duration: 0.6,
    ease: 'power3.in'
  })
  tl.to('.anim-start-header-part', {
    opacity: 1,
    y: 0,
    duration: 0.3,
    stagger: 0.1
  })
  tl.to('.anim-start-line-hor, .anim-start-line-vert', {
    '--insetValue': 0,
    duration: 0.7,
    stagger: 0.1,
  }, '<')
  tl.to('.anim-start-site-item', {
    x: 0,
    opacity: 1,
    stagger: 0.02,
    duration: 0.8
  }, '-=0.5')
  tl.to('.anim-start-show-ltr', {
    '--insetValue': 0,
    duration: 1,
    stagger: 0.05,
  }, '-=0.25')
  tl.to('.anim-start-masked-text', {
    '--text-fill': '100%',
    duration: 1.2,
  }, '<')


  // tl.timeScale(1)
})

