import Swiper from 'swiper';
import { Navigation, Grid, Pagination } from 'swiper/modules';

import {
  customFormatFraction,
  getElementByDataType,
  safeCall
} from '../../common/utils';

import 'swiper/css';
import 'swiper/css/grid';

safeCall(() => {
  const $slider = getElementByDataType('benefits-slider'); 
  /// "Tkachenko" - was needed for my sections. Just hide on mobile empty "deocrative" block
  if (window.screen.width <= 728) {
    const elementToHide = getElementByDataType('element-to-hide'); 
    if (elementToHide) {
      elementToHide.style.display = 'none'; 
    }
  }
  if (!$slider) return;

  const $pagination = getElementByDataType('slider-pagination', $slider);
  const $navPrev = getElementByDataType('slider-nav-prev', $slider);
  const $navNext = getElementByDataType('slider-nav-next', $slider);

  new Swiper($slider, {
    modules: [Navigation, Grid, Pagination],
    navigation: {
      enabled: true,
      nextEl: $navNext,
      prevEl: $navPrev
    },
    pagination: {
      enabled: true,
      el: $pagination,
      type: 'fraction',
      formatFractionCurrent: customFormatFraction,
      formatFractionTotal: customFormatFraction
    },
    loop: false,
    slidesPerView: 2,
    spaceBetween: 100,
    breakpoints: {
      320: {
        spaceBetween: 60,
        slidesPerView: 1.4,
        grid: {
          rows: 1
        }
      },
      1024: {
        slidesPerView: 2,
        spaceBetween: 48,
        grid: {
          fill: 'row',
          rows: 2
        }
      },
      1536: {
        spaceBetween: 100,
        grid: {
          fill: 'row',
          rows: 2
        }
      }
    }
  });
});

/// Version for solution

safeCall(() => {
  const $slider = getElementByDataType('solution-slider'); 

  if (!$slider) return;

  const $pagination = getElementByDataType('slider-pagination', $slider);
  const $navPrev = getElementByDataType('slider-nav-prev', $slider);
  const $navNext = getElementByDataType('slider-nav-next', $slider);

  new Swiper($slider, {
    modules: [Navigation, Grid, Pagination],
    navigation: {
      enabled: true,
      nextEl: $navNext,
      prevEl: $navPrev
    },
    pagination: {
      enabled: true,
      el: $pagination,
      type: 'fraction',
      formatFractionCurrent: customFormatFraction,
      formatFractionTotal: customFormatFraction
    },
    loop: false,
    slidesPerView: 3,
    spaceBetween: 100,
    breakpoints: {
      320: {
        spaceBetween: 60,
        slidesPerView: 1.4,
        grid: {
          rows: 1
        }
      },
      1024: {
        slidesPerView: 3,
        spaceBetween: 48,
        grid: {
          fill: 'row',
          rows: 1
        }
      },
      1536: {
        spaceBetween: 100,
        grid: {
          fill: 'row',
          rows: 1
        }
      }
    }
  });
});
