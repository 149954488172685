import {safeCall} from "../../common/utils";
import Swiper from 'swiper';
import {Navigation} from "swiper/modules";

safeCall(() => {
  const articlesSwipers = document.querySelectorAll('.articles-swiper-container');
  if (!articlesSwipers) return;
  articlesSwipers.forEach(swiper => {
    const articlesSwiper = swiper.querySelector('.articles-swiper');
    if(!articlesSwiper) return;
    const pageNumbers = swiper.querySelector('.articles-swiper-numbers');
    new Swiper(articlesSwiper, {
      slidesPerView: 1.04,
      loop: false,
      spaceBetween: 0,
      modules: [Navigation],
      navigation: {
        nextEl: '.articles-swiper-next',
        prevEl: '.articles-swiper-prev',
      },
      breakpoints: {
        1280: {
          slidesPerView: 3
        }
      },
      on: {
        slideChange: () => {
          const current = articlesSwiper.swiper.realIndex + 1;
          pageNumbers.querySelector('.current').innerHTML = current;
        }
      }
    });
  });
})
